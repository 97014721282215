<template>
  <div class="pay-page-container">
    <h2 class="pay-title">支付中心</h2>
    <div class="pay-info">
      <div class="pay-product-name">互联网求职面试实战营学习班</div>
      <div class="pay-product-price">¥ {{ total_amount }}</div>
      <div class="line"></div>
      <div class="pay-subtitle">请选择支付方式</div>
      <div class="pay-list">
        <div
          :class="active === 'alipay' ? 'pay-item active' : 'pay-item'"
          @click="switchPay('alipay')"
          id="pay-alipay"
        >
          <img
            class="pay-item-img"
            src="@/assets/images/pay/alipay.jpeg"
            alt=""
          />
        </div>
        <div
          :class="active === 'wechat' ? 'pay-item active' : 'pay-item'"
          id="pay-weixin"
          @click="switchPay('wechat')"
        >
          <img
            class="pay-item-img"
            src="@/assets/images/pay/WeChat.jpeg"
            alt=""
          />
        </div>
      </div>
      <div class="line"></div>
      <div class="pay-footer">
        <div>
          <span
            style="
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              line-height: 25px;
            "
            >实际支付金额：</span
          >
          <span class="pay-money">¥ {{ total_amount }}</span>
        </div>
        <button class="pay-button" @click="payMoney">马上支付</button>
      </div>
    </div>
    <el-dialog
      :visible.sync="qrCodeShow"
      :before-close="handleClose"
      width="500px"
    >
      <div class="pay-dialog-container">
        <img class="pay-dialog-qrcode" :src="payCode" alt="二维码" />
        <div class="pay-dialog-desc">
          <p class="pay-dialog-tips">请使用微信扫描左侧二维码以完成支付</p>
          <span class="pay-dialog-money">¥ {{ realMoney }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import payService from "@/global/service/pay";
import API from "@/global/request/api";
export default {
  data() {
    return {
      active: "alipay",
      alipayOrderId: null,
      wechatOrderId: null,
      total_amount: "",
      API,
      qrCodeShow: false,
      payCode: "",
      realMoney: "",
    };
  },
  created() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      const id = this.$route.params.id;
      payService.paySelect({ vip_price_id: id }).then((res) => {
        this.alipayOrderId = res.alipay_url.substring(
          res.alipay_url.indexOf("id=") + 3
        );
        this.wechatOrderId = res.wechat_url.substring(
          res.wechat_url.indexOf("id=") + 3
        );
        this.total_amount = res.total_amount;
      });
    },
    switchPay(name) {
      this.active = name;
    },
    payMoney() {
      const id =
        this.active === "wechat" ? this.wechatOrderId : this.alipayOrderId;
      if (this.active === "wechat") {
        payService.wechatQrcode({ order_id: id }).then((res) => {
          this.qrCodeShow = true;
          this.payCode = res.png_base64;
          this.realMoney = res.real_money;
        });
      } else {
        const url = `${API.aliPay}?order_id=${id}`;
        window.open(url);
      }
    },
    handleClose() {
      this.qrCodeShow = false;
      this.payCode = "";
      this.realMoney = "";
    },
  },
};
</script>

<style lang="less" scoped>
.pay-page-container {
  width: 1240px;
  margin: 0 auto;
}
.pay-title {
  font-size: 26px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  line-height: 37px;
  text-align: left;
  padding: 20px 0;
}
.pay-info {
  background: #fff;
  padding: 40px;
}
.pay-product-name {
  line-height: 37px;
  font-size: 26px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  text-align: left;
}
.pay-product-price {
  text-align: left;
  margin-top: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  margin-bottom: 39px;
}
.line {
  height: 1px;
  background: #d8d8d8;
}
.pay-money {
  font-size: 18px;
  color: #ff5c00;
}
.pay-list {
  display: flex;
  padding-bottom: 60px;
}
.pay-item {
  width: 200px;
  height: 80px;
  border: 1px solid #e4e4e4;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  &.active {
    border-color: #35b558;
  }
  .pay-item-img {
    height: 40px;
  }
}
.pay-subtitle {
  margin-bottom: 30px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  text-align: left;
  margin-top: 20px;
}
.pay-button {
  color: #fff;
  background: #35b558;
  width: 180px;
  height: 42px;
  transition: all 0.2s ease;
  border: 1px solid #35b558;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.pay-footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pay-dialog-container {
  display: flex;
  .pay-dialog-qrcode {
    width: 160px;
    height: 160px;
    margin-right: 20px;
  }
  .pay-dialog-desc {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .pay-dialog-tips {
    font-size: 18px;
    font-weight: 500;
    width: 230px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
    line-height: 30px;
  }
  .pay-dialog-money {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 64, 0, 1);
    line-height: 33px;
  }
}
</style>
