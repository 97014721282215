import API from "@/global/request/api";
import axios from "@/global/request/axios";

const payService = {
  vipPrices() {
    return axios.get(API.vipPrices);
  },
  paySelect(params) {
    return axios.post(API.paySelect, params);
  },
  orders(params) {
    return axios.get(API.orders, params);
  },
  orderDetail(id) {
    return axios.get(API.ordersId(id));
  },
  wechatQrcode(params) {
    return axios.get(API.wechatPay, params);
  },
  alipay(params) {
    return axios.get(API.aliPay, params);
  },
};

export default payService;
